<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          About us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">TradeWinds</h2>
          <p>
            Celebrating 30 years as the go-to news source in shipping, TradeWinds continues to offer market-leading coverage as it unpacks the key issues.
          </p>
          <p>
            Our global editorial team of experts offer peerless independent journalism, insight and business intelligence that simply can’t be found anywhere else.<br>
            We get close to the key movers and shakers on the ground in the US, UK, Norway, Greece, Singapore, China, India and all points in-between.
          </p>
          <p>
            Shipping never sleeps – so don’t miss a moment with TradeWinds’ unrivalled round-the-clock coverage.<br>
            <a href="https://www.tradewindsnews.com/subscription">Subscribe to TradeWinds today</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
